import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { ChoiceButton } from 'components/ChoiceButton'

interface Props {
  nextRoute: string
}

export const AgingQuestion1: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()
  const { user, setUser } = useUser()

  const genderBasedBackground =
    user.gender === 'm'
      ? 'bg-exercise-routine-male bg-center'
      : 'bg-exercise-routine-female bg-center'

  const onButtonClick = (choiceId: number) => {
    setUser({ ...user, agingQuestionChoiceId: choiceId })
    navigate(nextRoute)
  }

  const userAgeGroup = () => {
    const currentYear = new Date().getFullYear()
    const userAge = currentYear - Number(user.dateOfBirth)

    if (userAge < 20) {
      return 20
    } else if (userAge > 100) {
      return 90
    } else {
      return Math.floor(userAge / 10) * 10
    }
  }

  const headingText = () => {
    const ageGroup = userAgeGroup()
    const gender = user.gender === 'm' ? 'm' : 'f'
    return t(`aging_question_1.headline_${gender}`, { age: ageGroup })
  }

  return (
    <div className={`default-container flex flex-col ${genderBasedBackground}`}>
      <div className="flex h-full flex-col justify-between self-center overflow-auto px-7 pt-7 pb-10 lg:w-5/6 xl:w-5/12">
        <div className="flex flex-col gap-y-6">
          <h1
            className="text-center font-title text-2xl font-bold text-white xl:mt-0 xl:text-center"
            dangerouslySetInnerHTML={{
              __html: headingText(),
            }}
          />
        </div>

        <ButtonWrapper>
          <ChoiceButton
            onClick={() => onButtonClick(1)}
            value={t('aging_question_1.option_1')}
            id="option_1"
          />
          <ChoiceButton
            onClick={() => onButtonClick(2)}
            value={t('aging_question_1.option_2')}
            id="option_2"
          />
          <ChoiceButton
            onClick={() => onButtonClick(3)}
            value={t('aging_question_1.option_3')}
            id="option_3"
          />
        </ButtonWrapper>
      </div>
    </div>
  )
}
