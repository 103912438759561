import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Slide, ToastContainer, toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import { useUser } from 'context/UserContext'
import { useAutofocus } from 'hooks/useAutofocus'
import { useKeyPress } from 'hooks/useKeyPress'
import useApi from 'hooks/useApi'
import useFacebook from 'hooks/useFacebook'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import Input from 'components/Input'
import { RoutePaths } from 'route-paths'

interface Props {
  locale: string
  nextRoute: string
}

interface CurrentUser {
  id: number
}

export const Email: React.FC<Props> = ({ locale, nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { facebookTrack } = useFacebook()
  const { snowplowTrack } = useSnowplow()
  const { user, setUser } = useUser()
  const [email, setEmail] = useState('')
  const [canContinue, setCanContinue] = useState(false)
  const [loading, setLoading] = useState(false)
  const emailInput = useRef(null)
  const navigate = useNavigate()

  const { createUser, signIn } = useApi(locale)

  const signInAndRedirectToLoading = async (currentUser: CurrentUser) => {
    setUser({ id: currentUser.id, email, ...user })
    facebookTrack({ userId: currentUser.id, eventName: 'CompleteRegistration' })
    snowplowTrack({ eventName: 'signup_success' })

    try {
      const response = await signIn({
        email: email,
        password: user.password,
      })

      if (!!response.token) {
        setUser({ ...user, authToken: response.token, email })
        navigate(nextRoute)
      } else {
        Sentry.captureException(response)
        setLoading(false)
        toast.error(response)
      }
    } catch (e) {
      setLoading(false)
      toast.error(t('errors.unexpected_error'))
      Sentry.captureException(e)
    }
  }

  const createAndSignInUser = async () => {
    try {
      setUser({ ...user, email })

      const {
        user: currentUser,
        token: authToken,
        errors,
        error_code: errorCode,
      } = await createUser({ ...user, email })

      if (authToken) {
        signInAndRedirectToLoading(currentUser)
      }

      if (errorCode === 1) {
        navigate(RoutePaths.Login, {
          state: { message: t('errors.email_exists') },
        })

        return
      }

      if (!!errors) {
        Sentry.captureException(errors)
        setLoading(false)
        toast.error(errors)

        return
      }

      if (!authToken) {
        setLoading(false)
        const errorMessage =
          'Unexpected Error during signup Baseline: Auth Token Missing.'

        Sentry.captureException(errorMessage)
        toast.error(t('errors.unexpected_error'))
        return new Error(errorMessage)
      }
    } catch (e: any) {
      setLoading(false)
      toast.error(t('errors.unexpected_error'))
      Sentry.captureException(e)
    }
  }

  const onContinue = async () => {
    if (!canContinue || loading) {
      return
    }

    setLoading(true)

    createAndSignInUser()
  }

  useEffect(() => {
    setCanContinue(
      // eslint-disable-next-line max-len
      /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i.test(
        email,
      ),
    )
  }, [email])

  useAutofocus(emailInput)

  useKeyPress('ENTER', onContinue)

  return (
    <div className="mx-auto flex flex-col justify-start px-8 pt-9 lg:w-5/6 xl:w-5/12 xxsm:py-4">
      <Heading text={t('email.headline')} textColor="ar-dark-gray" />

      <p className="my-2 font-body text-sm text-ar-dark-gray xl:text-center xxsm:my-1">
        {t('email.subheadline')}
      </p>

      <div className="mt-8 xxsm:mt-2">
        <Input
          placeholder={t('email.placeholder')}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          type="email"
          inputMode="email"
          id="email_input"
          darkMode={false}
          ref={emailInput}
        />
      </div>
      <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center xxsm:my-2">
        {t('email.note')}
      </p>

      <ToastContainer position="bottom-center" transition={Slide} />

      <ContinueButton onClick={onContinue} disabled={!canContinue || loading}>
        <span
          className="disclaimer mt-3 mb-6 block w-full text-center font-body text-sm text-ar-light-gray"
          dangerouslySetInnerHTML={{
            __html: t('email.terms_and_privacy_acceptance', {
              terms_link: 'https://asanarebel.com/terms-of-use/',
              terms_and_conditions: t('email.terms_and_conditions'),
              privacy_and_policy_link:
                'https://asanarebel.com/privacy-policy-2/',
              privacy_policy: t('email.privacy_policy'),
              interpolation: {
                escapeValue: false,
              },
            }),
          }}
        />
      </ContinueButton>
    </div>
  )
}
